import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchPipe'
})
export class SearchPipe implements PipeTransform {
  transform(items: any[], args: string) {
    // Do nothing when nothing is in the element
    if (items == null) {
      return null;
    }
    if (args == null) {
      return items;
    }
    // Set all data to lowercase.
    return items.filter(item => item.voertuig.toLocaleLowerCase().indexOf(args.toLocaleLowerCase()) !== -1);
  }
}
