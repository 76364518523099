import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(public snackBar: MatSnackBar) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if (err.status === 401) {
                this.showError('Er is een fout opgetreden: ' + err.message, 'Sluiten');
            }

            if (err.status === 403) {
                this.showError('Er is een fout opgetreden: ' + err.message, 'Sluiten');
            }

            if (err.status === 500) {
                this.showError('Er is een fout opgetreden: ' + err.message, 'Sluiten');
            }

            const error = err.error.message || err;
            return throwError(error);
        }));
    }

    showError(title: string, button: string) {
        this.snackBar.open(title, button, {
          verticalPosition: 'top',
          duration: 5000,
        });
      }
}
