import { PipeTransform, Injectable, Pipe } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
    name: 'dateconverter'
})
@Injectable()
export class DateConverter extends DatePipe implements PipeTransform {
    override transform(item: string, format): any {
        if (item == null) {return ''; }
        const date = (item.substr(0, 19));

        return super.transform(date, format);
    }
}
