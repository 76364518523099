<head>
  <title>Expertteam Taxatie T{{taxID}}</title>
</head>

<app-headermenu></app-headermenu>

<ngx-loading [show]="loading" [config]="{fullScreenBackdrop: true,  primaryColour:'#63b5a2'}"></ngx-loading>

<div *ngIf="allItems">
  <div class="container">
    <div style="display:none;" class="lg-navigation-top">
      <div class="row">
        <div class="col-4 text-left">
          <a href="javascript:void(0);" (click)="navPrevPage()">
            <i class="fa fa-angle-left" aria-hidden="true"></i> Terug naar overzicht
          </a>
        </div>

        <div class="col-4 text-center">
          <i class="fa fa-file-pdf-o" aria-hidden="true"></i>
          <a href="javascript:void(0);" (click)="print()">
            <i class="fas fa-print"></i> Print pagina
          </a>
          <i class="fa fa-envelope-o" aria-hidden="true"></i>
        </div>

        <div class="col-4 text-right">
          <a href="javascript:void(0);" *ngIf="!bidsDisabled" (click)="nextTaxDisabled == false && navNext()">
            Volgende taxatie
            <i class="fa fa-angle-right" aria-hidden="true"></i>
          </a>
        </div>
      </div>
    </div>

    <div style="display:none;" class="sm-navigation-top">
      <div class="row">
        <div class="col-4">
          <a (click)="navPrevPage()">
            <i class="fa fa-angle-left" aria-hidden="true"></i> Overzicht</a>
        </div>

        <div class="col-4 align-center">
          <i class="fa fa-file-pdf-o" aria-hidden="true"></i>
          <a (click)="print()">
            <i class="fas fa-print"></i></a>
        </div>

        <div style="padding-bottom:5px;" class="col-4 align-right">
          <a *ngIf="!bidsDisabled" (click)="nextTaxDisabled == false && navNext()">Volgende
            <i class="fa fa-angle-right" aria-hidden="true"></i>
          </a>
        </div>
      </div>
    </div>

    <div class="col-md-12 no-padding">
      <div class="card">
        <div class="card-header" role="tab" id="headingThree">
          <h5 class="mb-0">
            <a href="javascript:void(0);" (click)="isCollapsed = !isCollapsed" aria-expanded="!isCollapsed"
              aria-controls="collapseThree">
              Voertuiggegevens <b>T{{taxID}}</b>
            </a>
          </h5>
        </div>

        <div [ngbCollapse]="isCollapsed" id="collapseThree" role="tabpanel" aria-labelledby="headingThree">
          <div class="card-body">
            <div class="row">
              <div class="col-md-4">
                <div class="row">
                  <div *ngIf="galleryImages.length === 0">
                    <img style="width:100%; height: 50%;" class="d-block img-fluid img-responsive"
                      src="../../../assets/images/no-image-available_1.png" />
                  </div>

                  <ngx-gallery style="height: 400px; z-index: 9999 !important;" *ngIf="galleryImages.length > 0"
                    [options]="galleryOptions" [images]="galleryImages">
                  </ngx-gallery>
                </div>

                <div class="row">
                  <div *ngIf="allItems.VehicleSpecification.Vehicle.officialImport == 'no'"
                    class="alert alert-warning  mt-2">
                    <strong>
                      <i class="fas fa-exclamation-triangle"></i>
                    </strong> Voertuig is geïmporteerd.<br> Land van herkomst:
                    <span *ngIf="allItems.VehicleSpecification.CountryOfOriginType == null">Onbekend</span>
                    <span
                      *ngIf="allItems.VehicleSpecification.CountryOfOriginType != null">{{allItems.VehicleSpecification.CountryOfOriginType.descriptionNl
                      | capitalize}}.</span>
                  </div>

                  <div *ngIf="allItems.VehicleSpecification.FuelType">
                    <div
                      *ngIf="allItems.VehicleSpecification.FuelType.name != 'diesel' && allItems.VehicleSpecification.VehicleType.name == 'commercial_vehicle'"
                      class="alert alert-warning  mt-2">
                      <strong>
                        <i class="fas fa-exclamation-triangle"></i>
                      </strong> Voertuig is een bedrijfsauto en brandstof is geen diesel!
                    </div>
                  </div>

                  <div *ngIf="allItems.selfRequested == true" class="alert alert-warning mt-2">
                    <strong>
                      <i class="fas fa-exclamation-triangle"></i>
                    </strong> Voertuig is ter taxatie aangeboden namens uw organisatie!
                  </div>

                  <div
                    *ngIf="allItems.dateDelivery != '' && ((allItems | dateDelivery).date | date: 'yyyy/MM/DD' | datedifference) > 90 && ((allItems | dateDelivery).date | date: 'yyyy/MM/DD' | datedifference) < 180">
                    <div class="alert alert-danger  mt-2">
                      <strong>
                        <i class="fas fa-exclamation-triangle"></i>
                      </strong> Voertuig is niet leverbaar binnen 3 maanden!
                    </div>
                  </div>

                  <div
                    *ngIf="allItems.dateDelivery != '' && ((allItems | dateDelivery).date | date: 'yyyy/MM/DD' | datedifference) > 181">
                    <div class="alert alert-danger  mt-2">
                      <strong>
                        <i class="fas fa-exclamation-triangle"></i>
                      </strong> Voertuig is niet leverbaar binnen 6 maanden!
                    </div>
                  </div>

                  <div *ngIf="allItems.VehicleSpecification.UsedAsType">
                    <div *ngIf="allItems.VehicleSpecification.UsedAsType.name == 'cab'"
                      class="alert alert-danger  mt-2">
                      <strong>
                        <i class="fas fa-exclamation-triangle"></i>
                      </strong> Voertuig is een taxi (geweest)!
                    </div>
                  </div>

                  <div *ngIf="allItems.VehicleSpecification.NapStatusType">
                    <div *ngIf="allItems.VehicleSpecification.NapStatusType.name == 'not_ok'"
                      class="alert alert-danger  mt-2">
                      <strong>
                        <i class="fas fa-exclamation-triangle"></i>
                      </strong> Voertuig heeft een ongeldige OKR!
                    </div>
                  </div>

                  <button *ngIf="!bidsDisabled  && onlyComments === false"
                    style="background-color: #63b5a2;  height: 40px !important;" type="button"
                    (click)="scrollToBidElement('bid')" class="btn btn-primary mt-2">
                    <i class="fas fa-gavel"></i> Breng een bod uit</button>

                  <button *ngIf="!bidsDisabled && onlyComments === true"
                    style="background-color: #63b5a2; margin-bottom: 10px;" type="button"
                    (click)="scrollToBidElement('bid')" class="btn btn-primary mt-2">
                    <i class="fas fa-gavel"></i> Plaats een opmerking</button>
                </div>
              </div>

              <div class="col-md-4">
                <table class="table table-condensed">
                  <tbody>
                    <tr>
                      <td class="color">Kenteken</td>
                      <td
                        *ngIf="(allItems.VehicleSpecification.Vehicle.registration | licensePipe: allItems.VehicleSpecification.Vehicle.registration) != null">
                        {{allItems.VehicleSpecification.Vehicle.registration
                        | licensePipe: allItems.VehicleSpecification.Vehicle.registration
                        }}
                      </td>
                      <td
                        *ngIf="(allItems.VehicleSpecification.Vehicle.registration | licensePipe: allItems.VehicleSpecification.Vehicle.registration) == null">
                        {{allItems.VehicleSpecification.Vehicle.registration}}
                      </td>
                    </tr>
                    <tr>
                      <td class="color">Voertuigsoort</td>
                      <td *ngIf="allItems.VehicleSpecification.VehicleType">
                        {{allItems.VehicleSpecification.VehicleType.descriptionNl
                        | capitalize}}</td>
                      <td *ngIf="!allItems.VehicleSpecification.VehicleType">Onbekend</td>
                    </tr>
                    <tr>
                      <td class="color">Merk</td>
                      <td>{{allItems.VehicleSpecification.Vehicle.make}}</td>
                    </tr>
                    <tr>
                      <td class="color">Model</td>
                      <td>{{allItems.VehicleSpecification.Vehicle.modelSpecific}}</td>
                    </tr>
                    <tr>
                      <td class="color">Type</td>
                      <td *ngIf="allItems.VehicleSpecification.type">{{allItems.VehicleSpecification.type}}</td>
                      <td *ngIf="!allItems.VehicleSpecification.type">Onbekend</td>
                    </tr>
                    <tr>
                      <td class="color">Uitvoering</td>
                      <td *ngIf="allItems.VehicleSpecification.typeCommercial">
                        {{allItems.VehicleSpecification.typeCommercial}}</td>
                      <td *ngIf="!allItems.VehicleSpecification.typeCommercial">Onbekend</td>
                    </tr>
                    <tr>
                      <td class="color">Brandstof</td>
                      <td *ngIf="allItems.VehicleSpecification.FuelType">
                        {{allItems.VehicleSpecification.FuelType.descriptionNl
                        | capitalize}}</td>
                      <td *ngIf="!allItems.VehicleSpecification.FuelType">Onbekend</td>
                    </tr>
                    <tr>
                      <td class="color">Carrosserie</td>
                      <td *ngIf="allItems.VehicleSpecification.BodyType">
                        {{allItems.VehicleSpecification.BodyType.descriptionNl
                        | capitalize}}</td>
                      <td *ngIf="!allItems.VehicleSpecification.BodyType">Onbekend</td>
                    </tr>
                    <tr>
                      <td class="color">Aantal deuren</td>
                      <td *ngIf="allItems.VehicleSpecification.numberOfDoors != ''">
                        {{allItems.VehicleSpecification.numberOfDoors}}</td>
                      <td *ngIf="allItems.VehicleSpecification.numberOfDoors == ''">Onbekend</td>
                    </tr>
                    <tr>
                      <td class="color">Aantal zitplaatsen</td>
                      <td *ngIf="allItems.VehicleSpecification.numberOfSeats != ''">
                        {{allItems.VehicleSpecification.numberOfSeats}}</td>
                      <td *ngIf="allItems.VehicleSpecification.numberOfSeats == ''">Onbekend</td>
                    </tr>
                    <tr>
                      <td class="color">Transmissie</td>
                      <td *ngIf="allItems.VehicleSpecification.TransmissionType">
                        {{allItems.VehicleSpecification.TransmissionType.descriptionNl
                        | capitalize}}</td>
                      <td *ngIf="!allItems.VehicleSpecification.TransmissionType">Onbekend</td>
                    </tr>

                    <tr>
                      <td class="color">Motorvermogen</td>
                      <td>{{allItems.VehicleSpecification.powerPK}} pk ({{allItems.VehicleSpecification.power}} kW)
                      </td>
                    </tr>

                    <tr>
                      <td class="color">Motorinhoud</td>
                      <td>{{allItems.VehicleSpecification.cylinderVolume | number:'1.0-0':'nl'}} cc</td>
                    </tr>
                    <tr>
                      <td class="color">Verwachte leverdatum</td>
                      <td *ngIf="allItems.dateDelivery; else noDate">
                        <span [ngStyle]="{'color': (allItems | dateDelivery : true : [31, 60, 90]).color}">
                          {{ (allItems | dateDelivery : true : [31, 60, 90]).date | date: 'dd-MM-yyyy' }}
                        </span>
                      </td>
                      <ng-template #noDate>
                        <td>Onbekend</td>
                      </ng-template>
                    </tr>
                    <tr>
                      <td class="color">Tellerstand</td>
                      <td>{{allItems.VehicleSpecification.mileage | number:'1.0-0':'nl'}}
                        {{allItems.VehicleSpecification.mileageType}}</td>
                    </tr>
                    <tr>
                      <td class="color">Tellerstand bij aflevering</td>
                      <td>{{allItems.VehicleSpecification.mileageDelivery | number:'1.0-0':'nl'}}
                        {{allItems.VehicleSpecification.mileageType}}</td>
                    </tr>
                    <tr>
                      <td class="color">NAP controle</td>
                      <td *ngIf="allItems.VehicleSpecification.NapStatusType">
                        {{allItems.VehicleSpecification.NapStatusType.descriptionNl}}</td>
                      <td *ngIf="!allItems.VehicleSpecification.NapStatusType" outline item-end>Onbekend</td>
                    </tr>
                    <tr>
                      <td class="color">Tellerhistorie</td>
                      <td *ngIf="allItems.VehicleSpecification.MileageHistoryStatusType">
                        <i *ngIf="allItems.VehicleSpecification.MileageHistoryStatusType.name == 'ok'"
                          class="fa fa-check" style="color:green;" aria-hidden="true"></i>
                        <i *ngIf="allItems.VehicleSpecification.MileageHistoryStatusType.name == 'not_ok'"
                          class="fa fa-times" style="color:red;" aria-hidden="true"></i>
                        <span
                          *ngIf="allItems.VehicleSpecification.MileageHistoryStatusType.name == 'not_given'">Onbekend</span>
                      </td>
                      <td *ngIf="allItems.VehicleSpecification.MileageHistoryStatusType == null">Onbekend</td>
                    </tr>
                    <tr>
                      <td class="color">Bouwjaar</td>
                      <td>{{allItems.VehicleSpecification.Vehicle.year}}</td>
                    </tr>
                    <tr>
                      <td class="color">Dist.riem vervangen</td>
                      <td *ngIf="allItems.VehicleSpecification.TimingBeltReplacedType !== null">
                        <span
                          *ngIf="allItems.VehicleSpecification.TimingBeltReplacedType.name == 'n.v.t.'">{{allItems.VehicleSpecification.TimingBeltReplacedType.descriptionNl
                          | capitalize}}</span>
                        <span
                          *ngIf="allItems.VehicleSpecification.TimingBeltReplacedType.name == 'no'">{{allItems.VehicleSpecification.TimingBeltReplacedType.descriptionNl
                          | capitalize}}</span>
                        <span *ngIf="allItems.VehicleSpecification.TimingBeltReplacedType.name == 'yes'">
                          {{allItems.VehicleSpecification.TimingBeltReplacedType.descriptionNl | capitalize}}
                          <span *ngIf="allItems.VehicleSpecification.timingBeltReplacedDate != null">
                            {{allItems.VehicleSpecification.timingBeltReplacedDate.date | date: 'dd-MM-yyyy'}}
                          </span>
                          <span *ngIf="allItems.VehicleSpecification.timingBeltReplacedMileage != null">
                            bij {{allItems.VehicleSpecification.timingBeltReplacedMileage | number:'4.0-0':'nl'}} km
                          </span>
                        </span>
                      </td>
                      <td *ngIf="allItems.VehicleSpecification.TimingBeltReplacedType == null">Onbekend</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="col-md-4">
                <table class="table table-condensed">
                  <tbody>
                    <tr>
                      <td class="color">Modeljaarreeks</td>
                      <td *ngIf="allItems.VehicleSpecification.modelYear != null">
                        {{allItems.VehicleSpecification.modelYear}}</td>
                      <td *ngIf="allItems.VehicleSpecification.modelYear == null">Onbekend</td>
                    </tr>
                    <tr>
                      <td class="color">Officiële import</td>
                      <td *ngIf="allItems.VehicleSpecification.Vehicle.officialImport == 'yes'">Ja</td>
                      <td *ngIf="allItems.VehicleSpecification.Vehicle.officialImport == 'no'">Nee, land van
                        herkomst:
                        <span *ngIf="allItems.VehicleSpecification.CountryOfOriginType == null">Onbekend</span>
                        <span *ngIf="allItems.VehicleSpecification.CountryOfOriginType != null">
                          {{allItems.VehicleSpecification.CountryOfOriginType.descriptionNl
                          | capitalize}}</span>
                      </td>
                      <td *ngIf="allItems.VehicleSpecification.Vehicle.officialImport == null">Onbekend</td>
                    </tr>
                    <tr>
                      <td class="color">Datum 1<sup>e</sup> toelating</td>
                      <td *ngIf="allItems.VehicleSpecification.Vehicle.dateFirstAdmission">
                        <b>{{allItems.VehicleSpecification.Vehicle.dateFirstAdmission.date | date:
                          'dd-MM-yyyy'}}</b>
                      </td>
                      <td *ngIf="!allItems.VehicleSpecification.Vehicle.dateFirstAdmission">Onbekend</td>
                    </tr>
                    <tr>
                      <td class="color">Datum deel 1a</td>
                      <td *ngIf="allItems.VehicleSpecification.Vehicle.dateFirstRegistration">
                        {{allItems.VehicleSpecification.Vehicle.dateFirstRegistration.date
                        | date: 'dd-MM-yyyy'}}</td>
                      <td *ngIf="!allItems.VehicleSpecification.Vehicle.dateFirstRegistration">Onbekend</td>
                    </tr>
                    <tr>
                      <td class="color">Datum deel 1b</td>
                      <td *ngIf="allItems.VehicleSpecification.dateLastRegistration">
                        {{allItems.VehicleSpecification.dateLastRegistration.date
                        | date: 'dd-MM-yyyy'}}</td>
                      <td *ngIf="!allItems.VehicleSpecification.dateLastRegistration">Onbekend</td>
                    </tr>
                    <tr>
                      <td class="color">APK vervaldatum</td>
                      <td *ngIf="allItems.VehicleSpecification.dateApkExpires">
                        <span
                          *ngIf="allItems.VehicleSpecification.dateApkExpires && (allItems.VehicleSpecification.dateApkExpires.date | datedifference) > 93">{{allItems.VehicleSpecification.dateApkExpires.date
                          | date: 'dd-MM-yyyy'}}</span>
                        <span style="color:red;"
                          *ngIf="(allItems.VehicleSpecification.dateApkExpires.date | datedifference) < 93">{{allItems.VehicleSpecification.dateApkExpires.date
                          | date: 'dd-MM-yyyy'}}</span>
                      </td>
                      <td *ngIf="!allItems.VehicleSpecification.dateApkExpires">Onbekend</td>
                    </tr>
                    <tr>
                      <td class="color">Voertuigstatus</td>
                      <td *ngIf="!allItems.VehicleSpecification.State">Onbekend</td>
                      <td *ngIf="allItems.VehicleSpecification.State">
                        <span *ngIf="allItems.VehicleSpecification.State != 'invalid'">Normaal</span>
                        <span style="color:red;"
                          *ngIf="allItems.VehicleSpecification.State == 'invalid'">Ongeldig</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="color">Herberekende nieuwprijs</td>
                      <td *ngIf="allItems.VehicleSpecification.newPrice">
                        &euro;{{allItems.VehicleSpecification.newPrice | number:'1.0-0':'nl'}}</td>
                      <td *ngIf="!allItems.VehicleSpecification.newPrice">Onbekend</td>
                    </tr>
                    <tr>
                      <td class="color">BTW / Marge</td>
                      <td *ngIf="allItems.VehicleSpecification.VatMarginType">
                        {{allItems.VehicleSpecification.VatMarginType.descriptionNl
                        | capitalize}}</td>
                      <td *ngIf="!allItems.VehicleSpecification.VatMarginType">Onbekend</td>
                    </tr>

                    <tr>
                      <td class="color">Bruto bpm</td>
                      <td *ngIf="allItems.VehicleSpecification.Vehicle.bpmAmount != null">
                        €{{allItems.VehicleSpecification.Vehicle.bpmAmount | number:'1.0-0':'nl'}}</td>
                      <td *ngIf="allItems.VehicleSpecification.Vehicle.bpmAmount == null">Onbekend</td>
                    </tr>

                    <tr>
                      <td class="color">Rest bpm</td>
                      <td *ngIf="allItems.VehicleSpecification.restBpm != null">
                        €{{allItems.VehicleSpecification.restBpm | number:'1.0-0':'nl'}}</td>
                      <td *ngIf="allItems.VehicleSpecification.restBpm == null">Onbekend</td>
                    </tr>

                    <tr>
                      <td class="color">Rest bpm (export)</td>
                      <td *ngIf="allItems.VehicleSpecification.restBpmExport != null">
                        €{{allItems.VehicleSpecification.restBpmExport | number:'1.0-0':'nl'}}</td>
                      <td *ngIf="allItems.VehicleSpecification.restBpmExport == null">Onbekend</td>
                    </tr>
                    <tr>
                      <td class="color">Energielabel</td>
                      <td *ngIf="allItems.VehicleSpecification.Vehicle.energyLabel == 'a'">
                        <b class="badge badge-primary"
                          style="background-color:darkgreen;">{{allItems.VehicleSpecification.Vehicle.energyLabel
                          | capitalize}}</b>
                      </td>
                      <td *ngIf="allItems.VehicleSpecification.Vehicle.energyLabel == 'b'">
                        <b class="badge badge-primary"
                          style="background-color:green;">{{allItems.VehicleSpecification.Vehicle.energyLabel
                          | capitalize}}</b>
                      </td>
                      <td *ngIf="allItems.VehicleSpecification.Vehicle.energyLabel == 'c'">
                        <b class="badge badge-primary"
                          style="background-color:rgb(161, 216, 161);">{{allItems.VehicleSpecification.Vehicle.energyLabel
                          | capitalize}}</b>
                      </td>
                      <td *ngIf="allItems.VehicleSpecification.Vehicle.energyLabel == 'd'">
                        <b class="badge badge-primary"
                          style="background-color:rgb(179, 179, 0);">{{allItems.VehicleSpecification.Vehicle.energyLabel|
                          capitalize}}
                        </b>
                      </td>
                      <td *ngIf="allItems.VehicleSpecification.Vehicle.energyLabel == 'e'">
                        <b class="badge badge-primary"
                          style="background-color:rgb(255, 157, 87);">{{allItems.VehicleSpecification.Vehicle.energyLabel
                          | capitalize}}</b>
                      </td>
                      <td *ngIf="allItems.VehicleSpecification.Vehicle.energyLabel == 'f'">
                        <b class="badge badge-primary"
                          style="background-color:rgb(194, 87, 0);">{{allItems.VehicleSpecification.Vehicle.energyLabel
                          | capitalize}}</b>
                      </td>
                      <td *ngIf="allItems.VehicleSpecification.Vehicle.energyLabel == 'g'">
                        <b class="badge badge-primary"
                          style="background-color:red;">{{allItems.VehicleSpecification.Vehicle.energyLabel
                          | capitalize}}</b>
                      </td>

                      <td *ngIf="allItems.VehicleSpecification.Vehicle.energyLabel == 'A'">
                        <b class="badge badge-primary"
                          style="background-color:darkgreen;">{{allItems.VehicleSpecification.Vehicle.energyLabel
                          | capitalize}}</b>
                      </td>
                      <td *ngIf="allItems.VehicleSpecification.Vehicle.energyLabel == 'B'">
                        <b class="badge badge-primary"
                          style="background-color:green;">{{allItems.VehicleSpecification.Vehicle.energyLabel
                          | capitalize}}</b>
                      </td>
                      <td *ngIf="allItems.VehicleSpecification.Vehicle.energyLabel == 'C'">
                        <b class="badge badge-primary"
                          style="background-color:rgb(161, 216, 161);">{{allItems.VehicleSpecification.Vehicle.energyLabel
                          | capitalize}}</b>
                      </td>
                      <td *ngIf="allItems.VehicleSpecification.Vehicle.energyLabel == 'D'">
                        <b class="badge badge-primary"
                          style="background-color:rgb(179, 179, 0);">{{allItems.VehicleSpecification.Vehicle.energyLabel|
                          capitalize}}
                        </b>
                      </td>
                      <td *ngIf="allItems.VehicleSpecification.Vehicle.energyLabel == 'E'">
                        <b class="badge badge-primary"
                          style="background-color:rgb(255, 157, 87);">{{allItems.VehicleSpecification.Vehicle.energyLabel
                          | capitalize}}</b>
                      </td>
                      <td *ngIf="allItems.VehicleSpecification.Vehicle.energyLabel == 'F'">
                        <b class="badge badge-primary"
                          style="background-color:rgb(194, 87, 0);">{{allItems.VehicleSpecification.Vehicle.energyLabel
                          | capitalize}}</b>
                      </td>
                      <td *ngIf="allItems.VehicleSpecification.Vehicle.energyLabel == 'G'">
                        <b class="badge badge-primary"
                          style="background-color:red;">{{allItems.VehicleSpecification.Vehicle.energyLabel
                          | capitalize}}</b>
                      </td>
                      <td *ngIf="allItems.VehicleSpecification.Vehicle.energyLabel == ''">Onbekend</td>
                      <td *ngIf="allItems.VehicleSpecification.Vehicle.energyLabel == null">Onbekend</td>
                    </tr>
                    <tr>
                      <td class="color">Kleur</td>
                      <td *ngIf="allItems.VehicleSpecification.ColorType">
                        {{allItems.VehicleSpecification.ColorType.descriptionNl
                        | capitalize}}</td>
                      <td *ngIf="!allItems.VehicleSpecification.ColorType">Onbekend</td>
                    </tr>
                    <tr>
                      <td class="color">Laktint</td>
                      <td *ngIf="allItems.VehicleSpecification.lactint != null">
                        {{allItems.VehicleSpecification.lactint}}</td>
                      <td *ngIf="allItems.VehicleSpecification.lactint == null || '' || undefined">Onbekend</td>
                    </tr>
                    <tr>
                      <td class="color">Laksoort</td>
                      <td *ngIf="allItems.VehicleSpecification.LacType != null">
                        {{allItems.VehicleSpecification.LacType.descriptionNl
                        | capitalize}}</td>
                      <td *ngIf="allItems.VehicleSpecification.LacType == null">Onbekend</td>
                    </tr>
                    <tr>
                      <td class="color">Bekleding</td>
                      <td *ngIf="allItems.VehicleSpecification.LiningType">
                        {{allItems.VehicleSpecification.LiningType.descriptionNl
                        | capitalize}}</td>
                      <td *ngIf="!allItems.VehicleSpecification.LiningType">Onbekend</td>
                    </tr>
                    <tr>
                      <td class="color">Interieurkleur</td>
                      <td *ngIf="allItems.VehicleSpecification.InteriorColorType">
                        {{allItems.VehicleSpecification.InteriorColorType.descriptionNl
                        | capitalize}}</td>
                      <td *ngIf="!allItems.VehicleSpecification.InteriorColorType">Onbekend</td>
                    </tr>
                    <tr>
                      <td class="color">Interieurafwerking</td>
                      <td *ngIf="allItems.VehicleSpecification.InteriorTouchType">
                        {{allItems.VehicleSpecification.InteriorTouchType.descriptionNl
                        | capitalize}}</td>
                      <td *ngIf="!allItems.VehicleSpecification.InteriorTouchType">Onbekend</td>
                    </tr>
                    <tr>
                      <td class="color">CO2-emissie</td>
                      <td *ngIf="allItems.VehicleSpecification.Vehicle.co2Emission">
                        {{allItems.VehicleSpecification.Vehicle.co2Emission}} gram/km</td>
                      <td *ngIf="!allItems.VehicleSpecification.Vehicle.co2Emission">Onbekend</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-12 no-padding">
      <div class="row">
        <div class="col-md-6">
          <div class="card">
            <div class="card-header" role="tab" id="headingAlgemeen">
              <h5 class="mb-0">
                <a href="javascript:void(0);" (click)="isGeneralCollapsed = !isGeneralCollapsed"
                  aria-expanded="!isGeneralCollapsed" aria-controls="collapseAlgemeen">
                  Algemeen
                </a>
              </h5>
            </div>
            <div [ngbCollapse]="isGeneralCollapsed" id="collapseAlgemeen" role="tabpanel"
              aria-labelledby="headingAlgemeen">
              <div class="card-body">
                <table class="table table-condensed">
                  <tbody>
                    <tr>
                      <td class="color">Aantal sleutels</td>
                      <td><span *ngIf="allItems.VehicleSpecification.numberOfKeys != null">
                          {{allItems.VehicleSpecification.numberOfKeys}}</span>
                        <span *ngIf="allItems.VehicleSpecification.numberOfKeys == null">Onbekend</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="color">Onderhoudsboekjes</td>
                      <div *ngIf="allItems.VehicleSpecification.MaintenanceGuideType">
                        <td *ngIf="allItems.VehicleSpecification.MaintenanceGuideType != null">
                          {{allItems.VehicleSpecification.MaintenanceGuideType.descriptionNl
                          | capitalize}}</td>
                        <td *ngIf="allItems.VehicleSpecification.MaintenanceGuideType == null">Onbekend</td>
                      </div>
                      <div *ngIf="!allItems.VehicleSpecification.MaintenanceGuideType">
                        <td>Onbekend</td>
                      </div>
                    </tr>
                    <tr>
                      <td class="color">Laatste beurt</td>
                      <td *ngIf="allItems.VehicleSpecification.lastMaintenanceMileage != null">
                        {{allItems.VehicleSpecification.lastMaintenanceMileage
                        | number}} {{allItems.VehicleSpecification.mileageType}}</td>
                      <td *ngIf="allItems.VehicleSpecification.lastMaintenanceMileage == null">Onbekend</td>
                    </tr>
                    <tr>
                      <td class="color">Gebruik als</td>
                      <td *ngIf="allItems.VehicleSpecification.UsedAsType != null">
                        {{allItems.VehicleSpecification.UsedAsType.descriptionNl}} <span style="color:red;"
                          *ngIf="allItems.VehicleSpecification?.isTaxi == true"> (volgens RDW: Taxi)</span>
                      </td>
                      <td *ngIf="allItems.VehicleSpecification.UsedAsType == null">Onbekend</td>
                    </tr>
                    <tr>
                      <td class="color">Duplicaatcode</td>
                      <td *ngIf="allItems.VehicleSpecification.duplicateCode != null">
                        {{allItems.VehicleSpecification.duplicateCode}}</td>
                      <td *ngIf="allItems.VehicleSpecification.duplicateCode == null">Onbekend</td>
                    </tr>
                    <tr>
                      <td class="color">Staat airco</td>
                      <td *ngIf="allItems.VehicleSpecification.AircoStatusType">
                        {{allItems.VehicleSpecification.AircoStatusType.descriptionNl
                        | capitalize}}</td>
                      <td *ngIf="!allItems.VehicleSpecification.AircoStatusType">Onbekend</td>
                    </tr>
                    <tr>
                    <tr>
                      <td class="color">Distributieriem vervangen</td>
                      <td *ngIf="allItems.VehicleSpecification.TimingBeltReplacedType">
                        {{allItems.VehicleSpecification.TimingBeltReplacedType.descriptionNl
                        | capitalize}}</td>
                      <td *ngIf="!allItems.VehicleSpecification.TimingBeltReplacedType">Onbekend</td>
                    </tr>
                    <tr>
                      <td class="color">Datum distributieriem</td>
                      <div *ngIf="allItems.VehicleSpecification.timingBeltReplacedDate">
                        <td
                          *ngIf="allItems.VehicleSpecification.timingBeltReplacedDate.date != '-0001-11-30 00:00:00.000000'">
                          {{allItems.VehicleSpecification.timingBeltReplacedDate.date
                          | date: 'dd-MM-yyyy'}}</td>
                        <td
                          *ngIf="allItems.VehicleSpecification.timingBeltReplacedDate.date == '-0001-11-30 00:00:00.000000'">
                          n.v.t.</td>
                      </div>

                      <div *ngIf="!allItems.VehicleSpecification.timingBeltReplacedDate">
                        <td>n.v.t.</td>
                      </div>
                    </tr>
                    <tr>
                      <td class="color">Tellerstand distributieriem</td>
                      <td *ngIf="allItems.VehicleSpecification.TimingBeltReplacedMileage != null">
                        {{allItems.VehicleSpecification.TimingBeltReplacedMileage}}</td>
                      <td *ngIf="allItems.VehicleSpecification.TimingBeltReplacedMileage == null">n.v.t.</td>
                    </tr>

                    <tr>
                      <td class="color">Boekjes</td>
                      <td *ngIf="allItems.VehicleSpecification.MaintenanceGuideType">
                        {{allItems.VehicleSpecification.MaintenanceGuideType.descriptionNl
                        | capitalize}}</td>
                      <td *ngIf="!allItems.VehicleSpecification.MaintenanceGuideType">Onbekend</td>
                    </tr>

                    <tr>
                      <td class="color">Historie</td>
                      <td *ngIf="allItems.VehicleSpecification.MaintenanceHistoryType">
                        {{allItems.VehicleSpecification.MaintenanceHistoryType.descriptionNl
                        | capitalize}}</td>
                      <td *ngIf="!allItems.VehicleSpecification.MaintenanceHistoryType">Onbekend</td>
                    </tr>
                    <tr>
                      <td class="color">Levering op</td>
                      <td *ngIf="allItems.VehicleSpecification.TiresType">
                        {{allItems.VehicleSpecification.TiresType.descriptionNl
                        | capitalize}}</td>
                      <td *ngIf="!allItems.VehicleSpecification.TiresType == null">Onbekend</td>
                    </tr>

                    <tr>
                      <td class="color">Extra banden</td>
                      <td *ngIf="allItems.VehicleSpecification.ExtraTiresType">
                        {{allItems.VehicleSpecification.ExtraTiresType.descriptionNl
                        | capitalize}}</td>
                      <td *ngIf="!allItems.VehicleSpecification.ExtraTiresType">Onbekend</td>
                    </tr>
                    <tr>
                  </tbody>
                </table>

                <div class="card-note">
                  <b>Opmerkingen van klant</b>
                  <p style="margin-top: 2px; color:red; "
                    *ngIf="allItems.VehicleSpecification.commentVehicleInfoCustomer"
                    [innerHTML]="allItems.VehicleSpecification.commentVehicleInfoCustomer"></p>
                  <p *ngIf="allItems.VehicleSpecification.extraInfo !== null" style="margin-top: 2px;"
                    [innerHTML]="allItems.VehicleSpecification.extraInfo"></p>
                  <p
                    *ngIf="!allItems.VehicleSpecification.commentVehicleInfoCustomer && !allItems.VehicleSpecification.extraInfo">
                    Niet opgegeven
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="card">
            <div class="card-header" role="tab" id="headingOpties">
              <h5 class="mb-0">
                <a href="javascript:void(0);" (click)="isOptionsCollapsed = !isOptionsCollapsed"
                  aria-expanded="!isOptionsCollapsed" aria-controls="collapseOpties">
                  Opties en Accessoires
                </a>
              </h5>
            </div>
            <div [ngbCollapse]="isOptionsCollapsed" id="collapseOpties" role="tabpanel" aria-labelledby="headingOpties">
              <div class="card-body">
                <h6>Standaard uitrusting</h6>
                <p *ngIf="allItems.VehicleSpecification.Accessories == null">Geen standaard uitrusting.</p>
                <div *ngIf="allItems.VehicleSpecification.Accessories != null">
                  <p *ngIf="allItems.VehicleSpecification.Accessories.Default == '[]'">Geen standaard uitrusting.</p>
                  <div style="margin-bottom:10px;" *ngIf="allItems.VehicleSpecification.Accessories">
                    <span style="margin-right:3px; color:rgb(65, 65, 65); background-color:#dfdfdf; "
                      *ngFor="let item of allItems.VehicleSpecification.Accessories.Default"
                      class="badge badge-secondary">{{item.optionName}}</span>
                  </div>
                </div>

                <div *ngIf="allItems.VehicleSpecification.Accessories">
                  <div *ngIf="allItems.VehicleSpecification.Accessories.MissingDefault != null">
                    <h6>Missende standaard uitrusting</h6>
                    <div style="margin-bottom:20px;" *ngIf="allItems.VehicleSpecification.Accessories.MissingDefault">
                      <span style="margin-right:3px; color:rgb(65, 65, 65); background-color:#dfdfdf; "
                        *ngFor="let item of allItems.VehicleSpecification.Accessories.MissingDefault"
                        class="badge badge-secondary">{{item.optionName}}</span>
                    </div>
                  </div>
                </div>

                <h6>Opties</h6>
                <p *ngIf="allItems.VehicleSpecification.Accessories == null">Geen opties.</p>
                <div *ngIf="allItems.VehicleSpecification.Accessories != null">
                  <p *ngIf="allItems.VehicleSpecification.Accessories.Optional == ''">Geen opties.</p>
                  <div style="margin-bottom:10px;" *ngIf="allItems.VehicleSpecification.Accessories">
                    <span style="margin-right:3px; color:rgb(65, 65, 65); background-color:#dfdfdf; "
                      *ngFor="let item of allItems.VehicleSpecification.Accessories.Optional"
                      class="badge badge-secondary">{{item.optionName}}</span>
                  </div>
                </div>

                <h6>Optiepaketten</h6>
                <p *ngIf="allItems.VehicleSpecification.Packages == null">Geen optiepaketten.</p>
                <div style="margin-bottom:20px;" *ngIf="allItems.VehicleSpecification.Packages">
                  <div *ngFor="let item of allItems.VehicleSpecification.Packages">
                    <span style="margin-right:3px; margin-bottom:5px; color:rgb(65, 65, 65);  background-color:#dfdfdf;"
                      class="badge badge-secondary" placement="top">{{item.packageName}}
                    </span>
                  </div>
                </div>

                <div style="margin-bottom: 15px;" class="card-note">
                  <b>Opmerkingen bij opties en accessoires</b>
                  <p *ngIf="allItems.VehicleSpecification.commentAccessories != '0'"
                    [innerHTML]="allItems.VehicleSpecification.commentAccessories"></p>
                  <p *ngIf="allItems.VehicleSpecification.commentAccessories == '0'">Niet opgegeven</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-12 no-padding">
        <div class="card">
          <div class="card-header" role="tab" id="headingVoertuigconditie">
            <h5 class="mb-0">
              <a href="javascript:void(0);" (click)="isConditionCollapsed = !isConditionCollapsed"
                aria-expanded="!isConditionCollapsed" aria-controls="collapseVoertuigconditie">
                Conditie
              </a>
            </h5>
          </div>
          <div [ngbCollapse]="isConditionCollapsed" id="collapseVoertuigconditie" role="tabpanel"
            aria-labelledby="headingVoertuigconditie">
            <div class="card-body">
              <div class="row">
                <div class="col-md-6">
                  <h6>Schades</h6>
                  <table class="table table-condensed">
                    <tbody>
                      <tr>
                        <td class="color">Beoordeling conditie</td>
                        <td *ngIf="allItems.VehicleSpecification.ConditionGeneralType != null">
                          {{allItems.VehicleSpecification.ConditionGeneralType.descriptionNl}}</td>
                        <td *ngIf="allItems.VehicleSpecification.ConditionGeneralType == null">Niet opgegeven</td>
                      </tr>
                      <tr>
                        <td class="color">Opknapkosten</td>
                        <td
                          *ngIf="allItems.VehicleSpecification.DamageSpecification && allItems.VehicleSpecification.DamageSpecification.repairCosts">
                          &euro;{{allItems.VehicleSpecification.DamageSpecification.repairCosts |
                          number:'1.0-0':'nl'}}
                        </td>
                        <td *ngIf="allItems.VehicleSpecification.DamageSpecification?.repairCosts == null">Niet
                          opgegeven</td>
                        <td
                          *ngIf="allItems.VehicleSpecification.DamageSpecification && allItems.VehicleSpecification.DamageSpecification.repairCosts == null">
                          Onbekend
                        </td>
                      </tr>
                      <tr>
                        <td class="color">Rookgeur</td>
                        <td *ngIf="allItems.VehicleSpecification.smoked == false">Nee</td>
                        <td *ngIf="allItems.VehicleSpecification.smoked == true">Ja</td>
                        <td *ngIf="allItems.VehicleSpecification.smoked == null">Onbekend</td>
                      </tr>
                      <tr>
                        <td class="color">Dierengeur</td>
                        <td *ngIf="allItems.VehicleSpecification.pets == false">Nee</td>
                        <td *ngIf="allItems.VehicleSpecification.pets == true">Ja</td>
                        <td *ngIf="allItems.VehicleSpecification.pets == null">Onbekend</td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="card-note">
                    <b>Opmerkingen bij schades</b>

                    <p *ngIf="allItems.VehicleSpecification.DamageSpecification?.description != null"
                      [innerHTML]="allItems.VehicleSpecification.DamageSpecification?.description"></p>

                    <p *ngIf="allItems.VehicleSpecification.commentTechnicalDefects != null"
                      [innerHTML]="allItems.VehicleSpecification.commentTechnicalDefects"></p>
                    <p
                      *ngIf="allItems.VehicleSpecification.DamageSpecification?.description == null && allItems.VehicleSpecification.commentTechnicalDefects == null">
                      Niet opgegeven
                      <br>
                    </p>
                  </div>
                </div>

                <div class="col-md-6">
                  <table *ngIf="allItems.VehicleSpecification.DamageSpecification"
                    class="table table-responsive table-condensed">
                    <thead>
                      <tr>
                        <th>Onderdeel</th>
                        <th>Beschadiging</th>
                        <th>Kosten</th>
                      </tr>
                    </thead>
                    <tbody *ngIf="allItems.VehicleSpecification.DamageSpecification.Damages != null">
                      <tr *ngFor="let item of allItems.VehicleSpecification.DamageSpecification.Damages">
                        <td>{{item.PartType.descriptionNl | capitalize}}</td>
                        <td>{{item.DamageDescriptions[0].Type.descriptionNl | capitalize}}</td>
                        <td>&euro;{{item.DamageDescriptions[0].repairCosts | number:'1.0-0':'nl'}}</td>
                      </tr>
                    </tbody>

                    <tbody *ngIf="allItems.VehicleSpecification.DamageSpecification.Damages == null">
                      <td colspan="2">Er zijn geen schades opgegeven.</td>
                    </tbody>
                  </table>

                  <div style="width:200px;" id="schades" class="carousel slide" data-ride="carousel">
                    <div class="carousel-inner" role="listbox">
                      <div class="carousel-item" *ngFor="let image of _damageAlbum; let i=index"
                        [ngClass]="{'active': i == 0}">
                        <a [href]="image.src" target="_blank"><img style="height:140px;"
                            class="d-block img-fluid img-responsive" [src]="image.src" /></a>
                      </div>

                    </div>
                    <a class="carousel-control-prev" href="#schades" role="button" data-slide="prev">
                      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                      <span class="sr-only">Vorige</span>
                    </a>
                    <a class="carousel-control-next" href="#schades" role="button" data-slide="next">
                      <span class="carousel-control-next-icon" aria-hidden="true"></span>
                      <span class="sr-only">Volgende</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row column-two">
        <div class="col-md-7 no-padding">
          <div style="margin-top:3px; margin-right:2px;" class="card card-color">
            <div class="card-header card-color" role="tab" id="headingVoertuigconditie">
              <h5 class="mb-0">
                <a href="javascript:void(0);" style="color:white !important;"
                  (click)="isBiddingCollapsed = !isBiddingCollapsed" aria-expanded="!isBiddingCollapsed"
                  aria-controls="collapseOne">Bieden</a>
              </h5>
            </div>
            <div [ngbCollapse]="isBiddingCollapsed" id="collapseOne" role="tabpanel" aria-labelledby="headingVoertuigconditie">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-6">
                    <h5 style="color:white;">
                      <b>{{allItems.VehicleSpecification.Vehicle.make}} {{allItems.VehicleSpecification.Vehicle.model}}</b>
                    </h5>
                    <br>
                    <table class="table table-condensed">
                      <tbody>
                        <tr>
                          <td>Taxatienummer</td>
                          <td>T{{allItems.id}}</td>
                        </tr>
                        <tr>
                          <td>Invoerdatum</td>
                          <td>{{allItems.created.date | date: 'dd-MM-yyyy'}}</td>
                        </tr>
                        <tr>
                          <td>Opknapkosten</td>
                          <td *ngIf="allItems.VehicleSpecification.DamageSpecification && allItems.VehicleSpecification.DamageSpecification.repairCosts">
                            &euro;{{allItems.VehicleSpecification.DamageSpecification.repairCosts | number:'1.0-0':'nl'}}</td>
                          <td *ngIf="allItems.VehicleSpecification.DamageSpecification && !allItems.VehicleSpecification.DamageSpecification.repairCosts">
                            Niet opgegeven</td>
                          <td *ngIf="!allItems.VehicleSpecification.DamageSpecification">Niet opgegeven</td>
                        </tr>
                        <tr>
                          <td>Verwachte leverdatum</td>
                          <td *ngIf="allItems.dateDelivery; else noDate"
                            [ngStyle]="{'color': (allItems | dateDelivery : true : [31, 60, 90]).color}">
                            {{ (allItems | dateDelivery : true : [31, 60, 90]).date | date: 'dd-MM-yyyy' }}
                          </td>
                          <ng-template #noDate>
                            <td>Onbekend</td>
                          </ng-template>
                        </tr>
                        <tr>
                          <td *ngIf="allItems.StatusType.name !== 'bought'">Verkocht voor</td>
                          <td *ngIf="allItems.StatusType.name === 'bought'">Te koop voor</td>
                          <td *ngIf="allItems.BidList.MyBid">
                            &euro;{{ allItems.assignedPrice | number:'1.0-0':'nl' }}
                          </td>
                          <td *ngIf="!allItems.assignedPrice">Geen bod uitgebracht</td>
                        </tr>
                        <tr>
                          <td>Sluitdatum</td>
                          <td *ngIf="allItems.BidList.BidTerm.closeDateBid != null">
                            {{allItems.BidList.BidTerm.closeDateBid.date | date: 'dd-MM-yyyy h:mm'}}</td>
                          <td *ngIf="allItems.BidList.BidTerm.closeDateBid == null">Onbekend</td>
                        </tr>
                      </tbody>
                    </table>
                    <div style="background-color:#ffffff; margin-bottom: 10px; color:black;" class="card-note">
                      <b>Opmerkingen van Autotaxatie Partners:</b>
                      <div *ngIf="allItems.Comments == ''">Geen opmerkingen.<br><br></div>
                      <div *ngIf="allItems.Comments">
                        <div *ngFor="let comment of allItems.Comments">
                          <div *ngIf="comment.Type.name === 'expert'">
                            {{comment.comment}}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
    
                  <div *ngIf="onlyComments == false" class="col-md-6">
                    <h4 for="basic-url">Nieuw bod</h4>
                    <div *ngIf="showBidError" class="alert alert-danger" role="alert">
                      Er is een fout opgetreden bij het opslaan van het bod. Probeer het later opnieuw.
                    </div>
                    <div *ngIf="showNoBidMessage" class="alert alert-warning" role="alert">
                      Voer een bedrag in om te bieden.
                    </div>
                    <div class="input-group">
                      <span style="padding-left:5px; padding-right:5px; padding-top:5px; margin-left:0px;background-color:white;font-size:20px; color:black;"
                        class="input-group-addon" id="basic-addon1">(incl. btw) € </span>
                      <input (keyup)="checkForDecimals()" (keyup.enter)="addBid(allItems.BidList.id)" step="1"
                        [(ngModel)]="newBid.bidAmount" type="number" min="0" class="form-control" aria-describedby="basic-addon1">
                    </div>
                    <div class="form-group">
                      <label style="margin-top:5px;" for="formGroupExampleInput2">Privé-opmerking (niet zichtbaar voor ATP)</label>
                      <textarea [(ngModel)]="newBid.bidComment" class="form-control" rows="4" cols="50"></textarea>
                    </div>
                    <div class="row g-2 pt-4">
                      <div class="col-md-4">
                        <button (click)="addBid(allItems.BidList.id, false)" type="button" class="btn btn-success w-100" style="height: 40px;">
                          <i class="fa fa-check" aria-hidden="true"></i> Bieden
                        </button>
                      </div>
                      <div class="col-md-8">
                        <button (click)="addBid(allItems.BidList.id, true)" type="button" class="btn btn-info w-100" style="height: 40px;">
                          Bieden en volgende <i class="fas fa-arrow-right" aria-hidden="true"></i>
                        </button>
                      </div>
                    </div>
                  </div>
    
                  <div *ngIf="onlyComments == true" class="col-md-6">
                    <h4 for="basic-url">Nieuwe opmerking</h4>
                    <div class="form-group">
                      <label style="margin-top:5px;" for="formGroupExampleInput2">Privé-opmerking (niet zichtbaar voor ATP)</label>
                      <textarea [(ngModel)]="newBid.bidComment" class="form-control" rows="4" cols="50"></textarea>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <button (click)="addComment(allItems.BidList.MyBid.id)" type="button" class="btn btn-success btn-block mt-2">
                          <i class="fa fa-check" aria-hidden="true"></i> Opmerking opslaan</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    
        <div id="bid" name="bid" class="col-md-5 no-padding">
          <div style="margin-top:3px;" class="card">
            <div class="card-header" role="tab" id="headingTwo">
              <h5 class="mb-0">
                <a href="javascript:void(0);" (click)="isBidHistoryCollapsed = !isBidHistoryCollapsed" aria-expanded="!isBidHistoryCollapsed" aria-controls="collapseTwo">
                  Biedhistorie
                </a>
              </h5>
            </div>
            <div [ngbCollapse]="isBidHistoryCollapsed" id="collapseTwo" role="tabpanel" aria-labelledby="headingTwo">
              <div class="card-body">
                <div class="row">
                  <table class="table" style="width: 100% !important;" *ngIf="allItems.BidList.MyBid != null || allItems.BidList.MyPreviousBids != ''">
                    <thead>
                      <tr>
                        <th>Datum</th>
                        <th>Persoon</th>
                        <th>Bieding</th>
                        <th>Opmerking</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngIf="allItems.BidList.MyBid">
                        <td *ngIf="allItems.BidList.MyBid.created">{{allItems.BidList.MyBid.created.date | date: 'dd-MM-yyyy'}}</td>
                        <td *ngIf="!allItems.BidList.MyBid.created">Onbekend</td>
                        <td>{{allItems.BidList.MyBid.Person.firstName}} {{allItems.BidList.MyBid.Person.infix}} {{allItems.BidList.MyBid.Person.lastName}}</td>
                        <td>&euro;{{allItems.BidList.MyBid.bid | number:'1.0-0':'nl'}}</td>
                        <td *ngIf="allItems.BidList.MyBid.comment">{{allItems.BidList.MyBid.comment}}</td>
                        <td *ngIf="!allItems.BidList.MyBid.comment">-</td>
                      </tr>
                      <tr *ngFor="let item of allItems.BidList.MyPreviousBids">
                        <td *ngIf="item.created">{{item.created.date | date: 'dd-MM-yyyy'}}</td>
                        <td *ngIf="!item.created">Onbekend</td>
                        <td>{{item.Person.firstName}} {{item.Person.infix}} {{item.Person.lastName}}</td>
                        <td>&euro;{{item.bid | number:'1.0-0':'nl'}}</td>
                        <td *ngIf="item.comment">{{item.comment}}</td>
                        <td *ngIf="!item.comment">-</td>
                      </tr>
                    </tbody>
                  </table>
                  <p *ngIf="allItems.BidList.MyPreviousBids == '' && allItems.BidList.MyBid == null">Er zijn nog geen biedingen uitgebracht.</p>
                </div>
                <div class="row">
                  <div class="col-sm-6 col-md-6">
                    <button style="height: 40px !important;" type="button" class="btn btn-outline-secondary" data-toggle="modal" data-target="#exampleModalCenter" (click)="searchVehicles();">Zoek vergelijkbare</button>
                  </div>
                  <div class="col-sm-6 col-md-6 align-right">
                    <button style="height: 40px !important;" type="button" class="btn btn-outline-secondary" (click)="compare()">Vergelijk op internet</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
  </div>

  <div style="display: none;" id="print">
    <div *ngIf="allItems">
      <div class="container-fluid">
        <h2 style="color:#d65d8c">T{{taxID}} - <b>{{allItems.VehicleSpecification.Vehicle.registration |
            licensePipe:
            allItems.VehicleSpecification.Vehicle.registration
            }}
          </b> - {{allItems.VehicleSpecification.Vehicle.make}} {{allItems.VehicleSpecification.Vehicle.model}} |
          {{allItems.VehicleSpecification.type}}</h2>

        <div class="row">
          <div class="col-md-6">
            <div *ngIf="allItems.VehicleSpecification.Vehicle.officialImport == 'no'" class="alert alert-warning">
              <strong>
                <i class="fas fa-exclamation-triangle"></i>
              </strong> Voertuig is geïmporteerd. Land van herkomst:
              <span *ngIf="allItems.VehicleSpecification.CountryOfOriginType == null">Onbekend</span>
              <span
                *ngIf="allItems.VehicleSpecification.CountryOfOriginType != null">{{allItems.VehicleSpecification.CountryOfOriginType.descriptionNl
                | capitalize}}.</span>
            </div>

            <div *ngIf="allItems.VehicleSpecification.FuelType">
              <div
                *ngIf="allItems.VehicleSpecification.FuelType.name != 'diesel' && allItems.VehicleSpecification.VehicleType.name == 'commercial_vehicle'"
                class="alert alert-warning">
                <strong>
                  <i class="fas fa-exclamation-triangle"></i>
                </strong> Voertuig is een bedrijfsauto en brandstof is geen diesel!
              </div>
            </div>

            <div *ngIf="allItems.selfRequested == true" class="alert alert-warning">
              <strong>
                <i class="fas fa-exclamation-triangle"></i>
              </strong> Voertuig is ter taxatie aangeboden namens uw organisatie!
            </div>

            <div *ngIf="allItems.VehicleSpecification.UsedAsType">
              <div *ngIf="allItems.VehicleSpecification.UsedAsType.name == 'cab'" class="alert alert-danger">
                <strong>
                  <i class="fas fa-exclamation-triangle"></i>
                </strong> Voertuig is een taxi (geweest)!
              </div>
            </div>

            <div *ngIf="allItems.VehicleSpecification.NapStatusType">
              <div *ngIf="allItems.VehicleSpecification.NapStatusType.name == 'not_ok'" class="alert alert-danger">
                <strong>
                  <i class="fas fa-exclamation-triangle"></i>
                </strong> Voertuig heeft een ongeldige OKR!
              </div>
            </div>
          </div>
        </div>

        <h4>Biedhistorie</h4>

        <table class="table table-sm" *ngIf="allItems.BidList.MyPreviousBids != ''">
          <thead>
            <tr>
              <th>Datum</th>
              <th>Persoon</th>
              <th>Bieding</th>
              <th>Opmerking</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngIf="allItems.BidList.MyBid">
              <td *ngIf="allItems.BidList.MyBid.lastUpdated">{{allItems.BidList.MyBid.lastUpdated.date | date:
                'dd-MM-yyyy'}}</td>
              <td *ngIf="!allItems.BidList.MyBid.lastUpdated">Onbekend</td>
              <td>{{allItems.BidList.MyBid.Person.firstName}} {{allItems.BidList.MyBid.Person.infix}}
                {{allItems.BidList.MyBid.Person.lastName}}</td>
              <td>&euro{{allItems.BidList.MyBid.bid | number:'1.0-0':'nl'}}</td>

              <td *ngIf="allItems.BidList.MyBid.comment">{{allItems.BidList.MyBid.comment}}</td>
              <td *ngIf="!allItems.BidList.MyBid.comment">-</td>
            </tr>

            <tr *ngFor="let item of allItems.BidList.MyPreviousBids">
              <td *ngIf="item.lastUpdated">{{item.lastUpdated.date | date: 'dd-MM-yyyy'}}</td>
              <td *ngIf="!item.lastUpdated">Onbekend</td>
              <td>{{item.Person.firstName}} {{item.Person.infix}} {{item.Person.lastName}}</td>
              <td>&euro;{{item.bid | number:'1.0-0':'nl'}}</td>

              <td *ngIf="item.comment">{{item.comment}}</td>
              <td *ngIf="!item.comment">-</td>
            </tr>
          </tbody>
        </table>

        <p *ngIf="allItems.BidList.MyPreviousBids == ''">Er zijn nog geen biedingen uitgebracht.</p>

        <h4>Voertuig</h4>

        <div class="row">
          <div class="col-md-6">
            <table class="table table-sm table-condensed">
              <tbody>
                <tr>
                  <td class="color">Kenteken</td>
                  <td>{{allItems.VehicleSpecification.Vehicle.registration | licensePipe:
                    allItems.VehicleSpecification.Vehicle.registration
                    }}
                  </td>
                </tr>
                <tr>
                  <td class="color">Voertuigsoort</td>
                  <td *ngIf="allItems.VehicleSpecification.VehicleType">
                    {{allItems.VehicleSpecification.VehicleType.descriptionNl
                    | capitalize}}</td>
                  <td *ngIf="!allItems.VehicleSpecification.VehicleType">Onbekend</td>
                </tr>
                <tr>
                  <td class="color">Merk</td>
                  <td>{{allItems.VehicleSpecification.Vehicle.make}}</td>
                </tr>
                <tr>
                  <td class="color">Model</td>
                  <td>{{allItems.VehicleSpecification.Vehicle.model}}</td>
                </tr>
                <tr>
                  <td class="color">Type</td>
                  <td>{{allItems.VehicleSpecification.type}}</td>
                </tr>
                <tr>
                  <td class="color">Uitvoering</td>
                  <td *ngIf="allItems.VehicleSpecification.typeCommercial != ''">
                    {{allItems.VehicleSpecification.typeCommercial}}</td>
                  <td *ngIf="allItems.VehicleSpecification.typeCommercial == ''">Onbekend</td>
                </tr>
                <tr>
                  <td class="color">Brandstof</td>
                  <td *ngIf="allItems.VehicleSpecification.FuelType">
                    {{allItems.VehicleSpecification.FuelType.descriptionNl
                    | capitalize}}</td>
                  <td *ngIf="!allItems.VehicleSpecification.FuelType">Onbekend</td>
                </tr>
                <tr>
                  <td class="color">Carrosserie</td>
                  <td *ngIf="allItems.VehicleSpecification.BodyType">
                    {{allItems.VehicleSpecification.BodyType.descriptionNl
                    | capitalize}}</td>
                  <td *ngIf="!allItems.VehicleSpecification.BodyType">Onbekend</td>
                </tr>
                <tr>
                  <td class="color">Aantal deuren</td>
                  <td *ngIf="allItems.VehicleSpecification.numberOfDoors != ''">
                    {{allItems.VehicleSpecification.numberOfDoors}}</td>
                  <td *ngIf="allItems.VehicleSpecification.numberOfDoors == ''">Onbekend</td>
                </tr>
                <tr>
                  <td class="color">Aantal zitplaatsen</td>
                  <td *ngIf="allItems.VehicleSpecification.numberOfSeats != ''">
                    {{allItems.VehicleSpecification.numberOfSeats}}</td>
                  <td *ngIf="allItems.VehicleSpecification.numberOfSeats == ''">Onbekend</td>
                </tr>
                <tr>
                  <td class="color">Transmissie</td>
                  <td *ngIf="allItems.VehicleSpecification.TransmissionType">
                    {{allItems.VehicleSpecification.TransmissionType.descriptionNl
                    | capitalize}}</td>
                  <td *ngIf="!allItems.VehicleSpecification.TransmissionType">Onbekend</td>
                </tr>

                <tr>
                  <td class="color">Motorvermogen</td>
                  <td>{{allItems.VehicleSpecification.powerPK}} pk ({{allItems.VehicleSpecification.power}} kW)</td>
                </tr>

                <tr>
                  <td class="color">Motorinhoud</td>
                  <td>{{allItems.VehicleSpecification.cylinderVolume | number:'1.0-0':'nl'}} cc</td>
                </tr>
                <tr>
                  <td class="color">Verwachte leverdatum</td>
                  <td *ngIf="allItems.dateDelivery; else noDate"
                    [ngStyle]="{'color': (allItems | dateDelivery : true : [30, 60, 90]).color}">
                    {{ (allItems | dateDelivery : true : [30, 60, 90]).date | date: 'dd-MM-yyyy' }}
                  </td>
                  <ng-template #noDate>
                    <td>Onbekend</td>
                  </ng-template>
                </tr>
                <tr>
                  <td class="color">Tellerstand</td>
                  <td>{{allItems.VehicleSpecification.mileage | number:'1.0-0':'nl'}}
                    {{allItems.VehicleSpecification.mileageType}}</td>
                </tr>
                <tr>
                  <td class="color">Tellerstand bij aflevering</td>
                  <td>{{allItems.VehicleSpecification.mileageDelivery | number:'1.0-0':'nl'}}
                    {{allItems.VehicleSpecification.mileageType}}</td>
                </tr>
                <tr>
                  <td class="color">NAP controle</td>
                  <td *ngIf="allItems.VehicleSpecification.NapStatusType">
                    {{allItems.VehicleSpecification.NapStatusType.descriptionNl}}</td>
                  <td *ngIf="!allItems.VehicleSpecification.NapStatusType" outline item-end>Onbekend</td>
                </tr>
                <tr>
                  <td class="color">Tellerhistorie</td>
                  <td *ngIf="allItems.VehicleSpecification.MileageHistoryStatusType">
                    <i *ngIf="allItems.VehicleSpecification.MileageHistoryStatusType.name == 'ok'" class="fa fa-check"
                      style="color:green;" aria-hidden="true"></i>
                    <i *ngIf="allItems.VehicleSpecification.MileageHistoryStatusType.name == 'not_ok'"
                      class="fa fa-times" style="color:red;" aria-hidden="true"></i>
                    <span
                      *ngIf="allItems.VehicleSpecification.MileageHistoryStatusType.name == 'not_given'">Onbekend</span>
                  </td>
                  <td *ngIf="allItems.VehicleSpecification.MileageHistoryStatusType == null">Onbekend</td>
                </tr>
                <tr>
                <tr>
                  <td class="color">Bouwjaar</td>
                  <td>{{allItems.VehicleSpecification.Vehicle.year}}</td>
                </tr>
                <tr>
                  <td class="color">Dist.riem vervangen</td>
                  <td *ngIf="allItems.VehicleSpecification.TimingBeltReplacedType !== null">
                    <span
                      *ngIf="allItems.VehicleSpecification.TimingBeltReplacedType.name == 'n.v.t.'">{{allItems.VehicleSpecification.TimingBeltReplacedType.descriptionNl
                      | capitalize}}</span>
                    <span
                      *ngIf="allItems.VehicleSpecification.TimingBeltReplacedType.name == 'no'">{{allItems.VehicleSpecification.TimingBeltReplacedType.descriptionNl
                      | capitalize}}</span>
                    <span *ngIf="allItems.VehicleSpecification.TimingBeltReplacedType.name == 'yes'">
                      {{allItems.VehicleSpecification.TimingBeltReplacedType.descriptionNl | capitalize}}
                      <span *ngIf="allItems.VehicleSpecification.timingBeltReplacedDate != null">
                        {{allItems.VehicleSpecification.timingBeltReplacedDate.date | date: 'dd-MM-yyyy'}}
                      </span>
                      <span *ngIf="allItems.VehicleSpecification.timingBeltReplacedMileage != null">
                        bij {{allItems.VehicleSpecification.timingBeltReplacedMileage | number:'4.0-0':'nl'}} km
                      </span>
                    </span>
                  </td>
                  <td *ngIf="allItems.VehicleSpecification.TimingBeltReplacedType == null">Onbekend</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-md-6">
            <table class="table table-sm table-condensed">
              <tbody>
                <tr>
                  <td class="color">Modeljaarreeks</td>
                  <td *ngIf="allItems.VehicleSpecification.modelYear != null">
                    {{allItems.VehicleSpecification.modelYear}}
                  </td>
                  <td *ngIf="allItems.VehicleSpecification.modelYear == null">Onbekend</td>
                </tr>
                <tr>
                  <td class="color">Officiële import</td>
                  <td *ngIf="allItems.VehicleSpecification.Vehicle.officialImport == 'yes'">Ja</td>
                  <td *ngIf="allItems.VehicleSpecification.Vehicle.officialImport == 'no'">Nee, land van herkomst:
                    <span *ngIf="allItems.VehicleSpecification.CountryOfOriginType == null">Onbekend</span>
                    <span *ngIf="allItems.VehicleSpecification.CountryOfOriginType != null">
                      {{allItems.VehicleSpecification.CountryOfOriginType.descriptionNl
                      | capitalize}}</span>
                  </td>
                  <td *ngIf="!allItems.VehicleSpecification.Vehicle.officialImport == null">Onbekend</td>
                </tr>
                <tr>
                  <td class="color">Datum 1<sup>e</sup> toelating</td>
                  <td *ngIf="allItems.VehicleSpecification.Vehicle.dateFirstAdmission">
                    <b>{{allItems.VehicleSpecification.Vehicle.dateFirstAdmission.date | date: 'dd-MM-yyyy'}}</b>
                  </td>
                  <td *ngIf="!allItems.VehicleSpecification.Vehicle.dateFirstAdmission">Onbekend</td>
                </tr>
                <tr>
                  <td class="color">Datum deel 1a</td>
                  <td *ngIf="allItems.VehicleSpecification.Vehicle.dateFirstRegistration">
                    {{allItems.VehicleSpecification.Vehicle.dateFirstRegistration.date
                    | date: 'dd-MM-yyyy'}}</td>
                  <td *ngIf="!allItems.VehicleSpecification.Vehicle.dateFirstRegistration">Onbekend</td>
                </tr>
                <tr>
                  <td class="color">Datum deel 1b</td>
                  <td *ngIf="allItems.VehicleSpecification.Vehicle.dateFirstRegistration">
                    {{allItems.VehicleSpecification.Vehicle.dateFirstRegistration.date
                    | date: 'dd-MM-yyyy'}}</td>
                  <td *ngIf="!allItems.VehicleSpecification.Vehicle.dateFirstRegistration">Onbekend</td>
                </tr>
                <tr>
                  <td class="color">APK vervaldatum</td>
                  <td *ngIf="allItems.VehicleSpecification.dateApkExpires">
                    {{allItems.VehicleSpecification.dateApkExpires.date
                    | date: 'dd-MM-yyyy'}}
                    <span style="color:red;"
                      *ngIf="(allItems.VehicleSpecification.dateApkExpires.date | date: 'yyyy/MM/DD' | datedifference) < 93 "></span>
                  </td>
                  <td *ngIf="!allItems.VehicleSpecification.dateApkExpires">Onbekend</td>
                </tr>
                <tr>
                  <td class="color">Voertuigstatus</td>
                  <td *ngIf="!allItems.VehicleSpecification.State">Onbekend</td>
                  <td *ngIf="allItems.VehicleSpecification.State">
                    <span *ngIf="allItems.VehicleSpecification.State != 'invalid'">Normaal</span>
                    <span style="color:red;" *ngIf="allItems.VehicleSpecification.State == 'invalid'">Ongeldig</span>
                  </td>
                </tr>
                <tr>
                  <td class="color">Herberekende nieuwprijs</td>
                  <td>€{{allItems.VehicleSpecification.newPrice | number:'1.0-0':'nl'}}</td>
                </tr>
                <tr>
                  <td class="color">BTW / Marge</td>
                  <td *ngIf="allItems.VehicleSpecification.VatMarginType">
                    {{allItems.VehicleSpecification.VatMarginType.descriptionNl
                    | capitalize}}</td>
                  <td *ngIf="!allItems.VehicleSpecification.VatMarginType">Onbekend</td>
                </tr>
                <tr>
                  <td class="color">Rest bpm</td>
                  <td *ngIf="allItems.VehicleSpecification.restBpm != null">
                    €{{allItems.VehicleSpecification.restBpm | number:'1.0-0':'nl'}}</td>
                  <td *ngIf="allItems.VehicleSpecification.restBpm == null">Onbekend</td>
                </tr>

                <tr>
                  <td class="color">Rest bpm (export)</td>
                  <td *ngIf="allItems.VehicleSpecification.restBpmExport != null">
                    €{{allItems.VehicleSpecification.restBpmExport | number:'1.0-0':'nl'}}</td>
                  <td *ngIf="allItems.VehicleSpecification.restBpmExport == null">Onbekend</td>
                </tr>
                <tr>
                  <td class="color">Energielabel</td>
                  <td *ngIf="allItems.VehicleSpecification.Vehicle.energyLabel == 'a'">
                    <b class="badge badge-primary"
                      style="background-color:darkgreen;">{{allItems.VehicleSpecification.Vehicle.energyLabel
                      | capitalize}}</b>
                  </td>
                  <td *ngIf="allItems.VehicleSpecification.Vehicle.energyLabel == 'b'">
                    <b class="badge badge-primary"
                      style="background-color:green;">{{allItems.VehicleSpecification.Vehicle.energyLabel
                      | capitalize}}</b>
                  </td>
                  <td *ngIf="allItems.VehicleSpecification.Vehicle.energyLabel == 'c'">
                    <b class="badge badge-primary"
                      style="background-color:rgb(161, 216, 161);">{{allItems.VehicleSpecification.Vehicle.energyLabel
                      | capitalize}}</b>
                  </td>
                  <td *ngIf="allItems.VehicleSpecification.Vehicle.energyLabel == 'd'">
                    <b class="badge badge-primary"
                      style="background-color:rgb(179, 179, 0);">{{allItems.VehicleSpecification.Vehicle.energyLabel|
                      capitalize}}
                    </b>
                  </td>
                  <td *ngIf="allItems.VehicleSpecification.Vehicle.energyLabel == 'e'">
                    <b class="badge badge-primary"
                      style="background-color:rgb(255, 157, 87);">{{allItems.VehicleSpecification.Vehicle.energyLabel
                      | capitalize}}</b>
                  </td>
                  <td *ngIf="allItems.VehicleSpecification.Vehicle.energyLabel == 'f'">
                    <b class="badge badge-primary"
                      style="background-color:rgb(194, 87, 0);">{{allItems.VehicleSpecification.Vehicle.energyLabel
                      | capitalize}}</b>
                  </td>
                  <td *ngIf="allItems.VehicleSpecification.Vehicle.energyLabel == 'g'">
                    <b class="badge badge-primary"
                      style="background-color:red;">{{allItems.VehicleSpecification.Vehicle.energyLabel
                      | capitalize}}</b>
                  </td>

                  <td *ngIf="allItems.VehicleSpecification.Vehicle.energyLabel == 'A'">
                    <b class="badge badge-primary"
                      style="background-color:darkgreen;">{{allItems.VehicleSpecification.Vehicle.energyLabel
                      | capitalize}}</b>
                  </td>
                  <td *ngIf="allItems.VehicleSpecification.Vehicle.energyLabel == 'B'">
                    <b class="badge badge-primary"
                      style="background-color:green;">{{allItems.VehicleSpecification.Vehicle.energyLabel
                      | capitalize}}</b>
                  </td>
                  <td *ngIf="allItems.VehicleSpecification.Vehicle.energyLabel == 'C'">
                    <b class="badge badge-primary"
                      style="background-color:rgb(161, 216, 161);">{{allItems.VehicleSpecification.Vehicle.energyLabel
                      | capitalize}}</b>
                  </td>
                  <td *ngIf="allItems.VehicleSpecification.Vehicle.energyLabel == 'D'">
                    <b class="badge badge-primary"
                      style="background-color:rgb(179, 179, 0);">{{allItems.VehicleSpecification.Vehicle.energyLabel|
                      capitalize}}
                    </b>
                  </td>
                  <td *ngIf="allItems.VehicleSpecification.Vehicle.energyLabel == 'E'">
                    <b class="badge badge-primary"
                      style="background-color:rgb(255, 157, 87);">{{allItems.VehicleSpecification.Vehicle.energyLabel
                      | capitalize}}</b>
                  </td>
                  <td *ngIf="allItems.VehicleSpecification.Vehicle.energyLabel == 'F'">
                    <b class="badge badge-primary"
                      style="background-color:rgb(194, 87, 0);">{{allItems.VehicleSpecification.Vehicle.energyLabel
                      | capitalize}}</b>
                  </td>
                  <td *ngIf="allItems.VehicleSpecification.Vehicle.energyLabel == 'G'">
                    <b class="badge badge-primary"
                      style="background-color:red;">{{allItems.VehicleSpecification.Vehicle.energyLabel
                      | capitalize}}</b>
                  </td>

                  <td *ngIf="allItems.VehicleSpecification.Vehicle.energyLabel == ''">Onbekend</td>
                  <td *ngIf="allItems.VehicleSpecification.Vehicle.energyLabel == null">Onbekend</td>
                </tr>
                <tr>
                  <td class="color">Kleur</td>
                  <td *ngIf="allItems.VehicleSpecification.ColorType">
                    {{allItems.VehicleSpecification.ColorType.descriptionNl
                    | capitalize}}</td>
                  <td *ngIf="!allItems.VehicleSpecification.ColorType">Onbekend</td>
                </tr>
                <tr>
                  <td class="color">Laktint</td>
                  <td *ngIf="allItems.VehicleSpecification.lactint != null">
                    {{allItems.VehicleSpecification.lactint}}
                  </td>
                  <td *ngIf="allItems.VehicleSpecification.lactint == null">Onbekend</td>
                </tr>
                <tr>
                  <td class="color">Laksoort</td>
                  <td *ngIf="allItems.VehicleSpecification.LacType != null">
                    {{allItems.VehicleSpecification.LacType.descriptionNl
                    | capitalize}}</td>
                  <td *ngIf="allItems.VehicleSpecification.LacType == null">Onbekend</td>
                </tr>
                <tr>
                  <td class="color">Bekleding</td>
                  <td *ngIf="allItems.VehicleSpecification.LiningType">
                    {{allItems.VehicleSpecification.LiningType.descriptionNl
                    | capitalize}}</td>
                  <td *ngIf="!allItems.VehicleSpecification.LiningType">Onbekend</td>
                </tr>
                <tr>
                  <td class="color">Interieurkleur</td>
                  <td *ngIf="allItems.VehicleSpecification.InteriorColorType">
                    {{allItems.VehicleSpecification.InteriorColorType.descriptionNl
                    | capitalize}}</td>
                  <td *ngIf="!allItems.VehicleSpecification.InteriorColorType">Onbekend</td>
                </tr>
                <tr>
                  <td class="color">Interieurafwerking</td>
                  <td *ngIf="allItems.VehicleSpecification.InteriorTouchType">
                    {{allItems.VehicleSpecification.InteriorTouchType.descriptionNl
                    | capitalize}}</td>
                  <td *ngIf="!allItems.VehicleSpecification.InteriorTouchType">Onbekend</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <h4>Algemeen</h4>
        <div class="row">
          <div class="col-md-6 no-padding">
            <table class="table table-sm table-condensed">
              <tbody>
                <tr>
                  <td class="color">Aantal sleutels</td>
                  <td *ngIf="allItems.VehicleSpecification.numberOfKeys != null">
                    {{allItems.VehicleSpecification.numberOfKeys}}</td>
                  <td *ngIf="allItems.VehicleSpecification.numberOfKeys == null">Onbekend</td>
                </tr>
                <tr>
                  <td class="color">Onderhoudsboekjes</td>
                  <div *ngIf="allItems.VehicleSpecification.MaintenanceGuideType">
                    <td *ngIf="allItems.VehicleSpecification.MaintenanceGuideType != null">
                      {{allItems.VehicleSpecification.MaintenanceGuideType.descriptionNl
                      | capitalize}}</td>
                    <td *ngIf="allItems.VehicleSpecification.MaintenanceGuideType == null">Onbekend</td>
                  </div>
                  <div *ngIf="!allItems.VehicleSpecification.MaintenanceGuideType">
                    <td>Onbekend</td>
                  </div>
                </tr>
                <tr>
                  <td class="color">Laatste beurt</td>
                  <td *ngIf="allItems.VehicleSpecification.lastMaintenanceMileage != null">
                    {{allItems.VehicleSpecification.lastMaintenanceMileage
                    | number}}</td>
                  <td *ngIf="allItems.VehicleSpecification.lastMaintenanceMileage == null">Onbekend</td>
                </tr>
                <tr>
                  <td class="color">Gebruik als</td>
                  <td *ngIf="allItems.VehicleSpecification.UsedAsType != null">
                    {{allItems.VehicleSpecification.UsedAsType.descriptionNl}}</td>
                  <td *ngIf="allItems.VehicleSpecification.UsedAsType == null">Onbekend</td>
                </tr>
                <tr>
                  <td class="color">Duplicaatcode</td>
                  <td *ngIf="allItems.VehicleSpecification.duplicateCode != null">
                    {{allItems.VehicleSpecification.duplicateCode}}</td>
                  <td *ngIf="allItems.VehicleSpecification.duplicateCode == null">Onbekend</td>
                </tr>
                <tr>
                  <td class="color">Staat airco</td>
                  <td *ngIf="allItems.VehicleSpecification.AircoStatusType">
                    {{allItems.VehicleSpecification.AircoStatusType.descriptionNl
                    | capitalize}}</td>
                  <td *ngIf="!allItems.VehicleSpecification.AircoStatusType">Onbekend</td>
                </tr>

                <tr>
                  <td class="color">Distributieriem vervangen</td>
                  <td *ngIf="allItems.VehicleSpecification.TimingBeltReplacedType">
                    {{allItems.VehicleSpecification.TimingBeltReplacedType.descriptionNl
                    | capitalize}}</td>
                  <td *ngIf="!allItems.VehicleSpecification.TimingBeltReplacedType">Onbekend</td>
                </tr>
                <tr>

                  <td class="color">Datum distributieriem</td>
                  <div *ngIf="allItems.VehicleSpecification.timingBeltReplacedDate">
                    <td *ngIf="allItems.VehicleSpecification.timingBeltReplacedDate.date != null">
                      {{allItems.VehicleSpecification.timingBeltReplacedDate.date
                      | date: 'dd-MM-yyyy'}}</td>
                    <td *ngIf="allItems.VehicleSpecification.timingBeltReplacedDate.date == null">
                      n.v.t.</td>
                  </div>

                  <div *ngIf="!allItems.VehicleSpecification.timingBeltReplacedDate">
                    <td>n.v.t.</td>
                  </div>
                </tr>
                <tr>
                  <td class="color">Tellerstand distributieriem</td>
                  <td *ngIf="allItems.VehicleSpecification.TimingBeltReplacedMileage != null">
                    {{allItems.VehicleSpecification.TimingBeltReplacedMileage}}</td>
                  <td *ngIf="allItems.VehicleSpecification.TimingBeltReplacedMileage == null">n.v.t.</td>
                </tr>
                <tr>
                  <td class="color">Boekjes</td>
                  <td *ngIf="allItems.VehicleSpecification.MaintenanceGuideType">
                    {{allItems.VehicleSpecification.MaintenanceGuideType.descriptionNl
                    | capitalize}}</td>
                  <td *ngIf="!allItems.VehicleSpecification.MaintenanceGuideType">Onbekend</td>
                </tr>
                <tr>
                  <td class="color">Historie</td>
                  <td *ngIf="allItems.VehicleSpecification.MaintenanceHistoryType">
                    {{allItems.VehicleSpecification.MaintenanceHistoryType.descriptionNl
                    | capitalize}}</td>
                  <td *ngIf="!allItems.VehicleSpecification.MaintenanceHistoryType">Onbekend</td>
                </tr>
                <tr>
              </tbody>
            </table>
          </div>

          <div class="col-md-6">
            <div class="card-note">
              <b>Opmerkingen van klant</b>
              <p style="margin-top: 2px; color:red; " *ngIf="allItems.VehicleSpecification.commentVehicleInfoCustomer"
                [innerHTML]="allItems.VehicleSpecification.commentVehicleInfoCustomer"></p>
              <p *ngIf="allItems.VehicleSpecification.extraInfo !== null" style="margin-top: 2px;"
                [innerHTML]="allItems.VehicleSpecification.extraInfo"></p>
              <p
                *ngIf="!allItems.VehicleSpecification.commentVehicleInfoCustomer && !allItems.VehicleSpecification.extraInfo">
                Niet opgegeven
              </p>
            </div>
          </div>
        </div>

        <h4>Opties en accessoires</h4>

        <h6>Standaard uitrusting</h6>
        <p *ngIf="allItems.VehicleSpecification.Accessories == null">Geen standaard uitrusting.</p>
        <div *ngIf="allItems.VehicleSpecification.Accessories != null">
          <p *ngIf="allItems.VehicleSpecification.Accessories.Default == '[]'">Geen standaard uitrusting.</p>
          <div style="margin-bottom:10px;" *ngIf="allItems.VehicleSpecification.Accessories">
            <span style="margin-right:3px; background-color:#c4c4c4; "
              *ngFor="let item of allItems.VehicleSpecification.Accessories.Default"
              class="badge badge-secondary">{{item.optionName}}</span>
          </div>
        </div>

        <div *ngIf="allItems.VehicleSpecification.Accessories">
          <div *ngIf="allItems.VehicleSpecification.Accessories.MissingDefault != null">
            <h6>Missende standaard uitrusting</h6>
            <div style="margin-bottom:20px;" *ngIf="allItems.VehicleSpecification.Accessories.MissingDefault">
              <span style="margin-right:3px; color:rgb(65, 65, 65); background-color:#dfdfdf; "
                *ngFor="let item of allItems.VehicleSpecification.Accessories.MissingDefault"
                class="badge badge-secondary">{{item.optionName}}</span>
            </div>
          </div>
        </div>

        <h6>Opties</h6>
        <p *ngIf="allItems.VehicleSpecification.Accessories == null">Geen opties.</p>
        <div *ngIf="allItems.VehicleSpecification.Accessories != null">
          <p *ngIf="allItems.VehicleSpecification.Accessories.Optional == ''">Geen opties.</p>
          <div style="margin-bottom:20px;" *ngIf="allItems.VehicleSpecification.Accessories">
            <span style="margin-right:3px; background-color:#c4c4c4;"
              *ngFor="let item of allItems.VehicleSpecification.Accessories.Optional"
              class="badge badge-secondary">{{item.optionName}}</span>
          </div>
        </div>

        <h6>Optiepaketten</h6>
        <p *ngIf="allItems.VehicleSpecification.Packages == null">Geen optiepaketten.</p>
        <div style="margin-bottom:20px;" *ngIf="allItems.VehicleSpecification.Packages">
          <div *ngFor="let item of allItems.VehicleSpecification.Packages">
            <span style="margin-right:3px; margin-bottom:5px; color:rgb(65, 65, 65);  background-color:#dfdfdf;"
              class="badge badge-secondary" placement="top">{{item.packageName}}
            </span>
          </div>
        </div>

        <div class="pagebreak">
          <h6>Opmerkingen bij opties en accessoires</h6>
          <p *ngIf="allItems.VehicleSpecification.commentAccessories != '0'"
            [innerHTML]="allItems.VehicleSpecification.commentAccessories"></p>
          <p *ngIf="allItems.VehicleSpecification.commentAccessories == null">Niet opgegeven</p>

          <h4>Voertuigconditie en beschadigingen</h4>
          <div class="row">
            <div class="col-md-6">
              <table class="table table-sm table-condensed">
                <tbody>
                  <tr>
                    <td class="color">Beoordeling conditie</td>
                    <td
                      *ngIf="allItems.VehicleSpecification.DamageSpecification && allItems.VehicleSpecification.DamageSpecification.description != null"
                      [innerHTML]="allItems.VehicleSpecification.DamageSpecification.description"></td>
                    <td
                      *ngIf="allItems.VehicleSpecification.DamageSpecification && allItems.VehicleSpecification.DamageSpecification.description == null">
                      Niet opgegeven
                    </td>
                  </tr>
                  <tr>
                    <td class="color">Opknapkosten</td>
                    <td
                      *ngIf="allItems.VehicleSpecification.DamageSpecification  && allItems.VehicleSpecification.DamageSpecification.repairCosts">
                      &euro{{allItems.VehicleSpecification.DamageSpecification.repairCosts | number:'1.0-0':'nl'}}
                    </td>
                    <td
                      *ngIf="allItems.VehicleSpecification.DamageSpecification && allItems.VehicleSpecification.DamageSpecification.repairCosts == null">
                      Onbekend</td>
                  </tr>
                  <tr>
                    <td class="color">Rookgeur</td>
                    <td *ngIf="allItems.VehicleSpecification.smoked == false">Nee</td>
                    <td *ngIf="allItems.VehicleSpecification.smoked == true">Ja</td>
                    <td *ngIf="allItems.VehicleSpecification.smoked == null">Onbekend</td>
                  </tr>
                  <tr>
                    <td class="color">Dierengeur</td>
                    <td *ngIf="allItems.VehicleSpecification.pets == false">Nee</td>
                    <td *ngIf="allItems.VehicleSpecification.pets == true">Ja</td>
                    <td *ngIf="allItems.VehicleSpecification.pets == null">Onbekend</td>
                  </tr>
                </tbody>
              </table>
              <div class="card-note">
                <b>Opmerkingen bij schades</b>
                <p style="margin-top: 2px;"
                  *ngIf="allItems.VehicleSpecification.DamageSpecification != null && allItems.VehicleSpecification.DamageSpecification.description != null"
                  [innerHTML]="allItems.VehicleSpecification.DamageSpecification.description"></p>
                <p style="margin-top: 2px;" *ngIf="allItems.VehicleSpecification.commentTechnicalDefects != null"
                  [innerHTML]="allItems.VehicleSpecification.commentTechnicalDefects"></p>
                <p style="margin-top: 2px;"
                  *ngIf="(allItems.VehicleSpecification.DamageSpecification == null || allItems.VehicleSpecification.DamageSpecification.description == null) && allItems.VehicleSpecification.commentTechnicalDefects == null">
                  Niet
                  opgegeven
                  <br>
                </p>
              </div>
            </div>

            <div class="col-md-6">
              <table style="width: 100% !important;" class="table table-sm">
                <thead>
                  <tr>
                    <th>Onderdeel</th>
                    <th>Beschadiging</th>
                    <th>Kosten</th>
                  </tr>
                </thead>
                <tbody
                  *ngIf=" allItems.VehicleSpecification.DamageSpecification && allItems.VehicleSpecification.DamageSpecification.Damages != null">
                  <tr *ngFor="let item of allItems.VehicleSpecification.DamageSpecification.Damages">
                    <td>{{item.DamageDescriptions[0].Type.descriptionNl | capitalize}}</td>
                    <td>{{item.PartType.descriptionNl | capitalize}}</td>
                    <td>&euro;{{item.DamageDescriptions[0].repairCosts | number:'1.0-0':'nl'}}</td>
                  </tr>
                </tbody>

                <tbody
                  *ngIf="allItems.VehicleSpecification.DamageSpecification && allItems.VehicleSpecification.DamageSpecification.Damages == null">
                  <td colspan="4">Er zijn geen schades opgegeven.</td>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-search *ngIf="showSearch && allItems" [settings]="allItems"></app-search>

  <div style="height: 100px;"></div>