import { Component,  OnInit } from '@angular/core';
import { OverviewComponent } from '../../Directives';
import { SettingsService } from '../../Services';

@Component({
    selector: 'app-biedingen',
    templateUrl: 'biedingen.component.html',
    styleUrls: ['biedingen.component.css'],
})

export class BiedingenComponent implements OnInit {

    pageSettings: any;

    constructor(public setting: SettingsService, public overview: OverviewComponent) { }

    ngOnInit() {
        let viewType: any;
        if (this.setting.getSetting('show_overview_layout') === 'Foto' ) {
            viewType = 'grid';
        }
        if (this.setting.getSetting('show_overview_layout') === 'Lijst' ) {
            viewType = 'list';
        }

        this.pageSettings = ({
            // View options
            Title: 'Bieden', // The main title of the page.
            HtmlTitle: 'Expertteam - Bieden', // The name of the tab in the browser.
            DefaultViewType: viewType, // This can be set to list or grid.
            MaxResultsSettingName: 'valuations_per_page_bieden',

            AllowBidding: true, // This will enable the option to bid when navigating to detail page
            AllowNavigationOnClick: true, // This will allow the user to navigate to the detail page when row is clicked.

            EnableGridView: true, // The 'gallery' view.
            EnableListView: true, // Default table view.

            EnableOrdering: false, // This field is depricated, see the table fields for per field ordering.
            EnableFiltering: true, // Enable or disable the filering field, if disabled the default filter will be used.
            EnableSearching: true, // Enable or disable the searching field.
            EnableAmoutOfResults: true,
            EnableItemClicked: true,

            RefreshInterval: 120000, // Time in MS when to fetch new data.

            // These fields are used to set the search type when searching.
            SearchFields: [{ DisplayName: 'Taxatienummer', RequestFilter: 'id' },
            { DisplayName: 'Kenteken', RequestFilter: 'Vehicle.registration' },
            { DisplayName: 'Merk', RequestFilter: 'Vehicle.make' },
            { DisplayName: 'Model', RequestFilter: 'Vehicle.modelSpecific' },
            { DisplayName: 'Bouwjaar', RequestFilter: 'Vehicle.year' }],

            // These fields are used to fill the table with some data.
            // When ordering is disabled in an table field the icon for ordering will not show.
            TableFields: [{ApiField: 'id', DisplayName: 'Taxnr', EnableOrdering: true, orderField: 'id'},
            {ApiField: 'BidTermDate', DisplayName: 'Verloopt', EnableOrdering: true, orderField: 'BidTerm.closeDateBid'},
            {ApiField: 'VehicleMakeType', DisplayName: 'Voertuig', EnableOrdering: true, orderField: 'Vehicle.make'},
            {ApiField: 'VehicleLicense', DisplayName: 'Kenteken', EnableOrdering: false, orderField: ''},
            {ApiField: 'VehicleYear', DisplayName: 'Jaar', EnableOrdering: true, orderField: 'Vehicle.year'},
            {ApiField: 'VehicleFuel', DisplayName: 'Brandstof', EnableOrdering: true, orderField: 'VehicleSpecification.FuelType'},
            {ApiField: 'VehicleMileage', DisplayName: 'Kmstand', EnableOrdering: true, orderField: 'VehicleSpecification.mileage'},
            {ApiField: 'VehicleBodyType', DisplayName: 'Carrosserie', EnableOrdering: true, orderField: 'VehicleSpecification.BodyType'},
            {ApiField: 'BidMyBid', DisplayName: 'Mijn bod', EnableOrdering: false, orderField: ''}],

            // hese fields are used to set the filter types.
            FilteringFields: [{ DisplayName: 'Alle taxaties', RequestFilter: '' },
            { DisplayName: 'Eigen taxaties', RequestFilter: ['currentBidTerm', 'selfRequested'] },
            { DisplayName: 'Geboden', RequestFilter: ['currentBidTerm', 'withBid']},
            { DisplayName: 'Niet geboden', RequestFilter: ['currentBidTerm', 'withoutBid']}],

            // API fields
            DefaultOrdering: 'DESC', // This can be set to ASC and DESC and will affect the view's ordering.
            DefaultFilter: { DisplayName: 'Alle taxaties', RequestFilter: 'currentBidTerm' },
            DefaultSearchField: { DisplayName: 'Taxatienummer', RequestFilter: 'id' },
            ApiFields: ["id", "VehicleSpecification-Vehicle-make", "VehicleSpecification-Vehicle-modelSpecific",
            "BidList", "VehicleSpecification-Vehicle-registration", "VehicleSpecification-Vehicle-year",
            "VehicleSpecification-BodyType", "VehicleSpecification-FuelType", "VehicleSpecification-mileage",
             "VehicleSpecification-Images", "VehicleSpecification-type", "ExpertViews", "VehicleSpecification-mileageType"]
        });
    }
}
