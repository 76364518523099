<!-- Mobile (Small screen) header -->
<div class="container d-block d-md-none">
  <div class="row align-items-center">
    <div class="col-6 d-flex align-items-center">
      <a routerLink="/home">
        <img src="../../../assets/images/logo-icon.png" class="img-fluid headersm-logo" style="max-width: 50px;" />
      </a>
      <h4 class="headersm-title ml-2 mb-0">Expertteam</h4>
    </div>
    <div class="col-2 text-right position-relative">
      <div ngbDropdown class="d-inline-block">
        <button id="btnGroupDrop1" type="button" class="btn btn-secondary btn-sm dropdown-toggle"
          style="background-color:#63b5a2; white-space: nowrap;" ngbDropdownToggle>
          <i class="fas fa-user"></i> {{userData.Person.initials}} {{userData.Person.infix}}
          {{userData.Person.lastName}}
        </button>
        <div ngbDropdownMenu aria-labelledby="btnGroupDrop1" class="dropdown-menu dropdown-menu-end"
          style="position: absolute; right: 0; left: auto;">
          <div class="dropdown-item">
            <i class="far fa-user"></i> <b>{{userData.Person.initials}} {{userData.Person.infix}}
              {{userData.Person.lastName}}</b>
          </div>
          <div class="dropdown-item">
            <i class="far fa-building"></i> {{userData.Company.name}}
          </div>
          <div class="dropdown-divider"></div>
          <a *ngFor="let index of headermenu" class="dropdown-item" [routerLink]="index.href">
            <i class="{{index.icon}}"></i> {{index.title}}
          </a>
          <a href="#" class="dropdown-item" (click)="logout()">
            <i class="fas fa-sign-out-alt"></i> Uitloggen
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Desktop (Large screen) header -->
<div class="container mt-3 d-none d-md-block">
  <div class="row align-items-center">
    <div class="col-md-4">
      <a routerLink="/home">
        <img src="../../../assets/images/logo-text.png" class="img-fluid header-logo" />
      </a>
    </div>
    <div class="col-md-4 text-center">
      <h2 class="header-title">Expertteam</h2>
    </div>
    <div class="col-md-4 text-end">
      <div class="d-flex flex-column align-items-end">
        <b><i class="far fa-building"></i> {{userData.Company.name}}</b>
        <div ngbDropdown class="d-inline-block mt-2">
          <button type="button" class="btn btn-secondary dropdown-toggle btn-sm" style="background-color:#63b5a2"
            id="dropdownUser" ngbDropdownToggle>
            <i class="fas fa-user"></i> {{userData.Person.firstName}} {{userData.Person.infix}}
            {{userData.Person.lastName}}
          </button>
          <div ngbDropdownMenu aria-labelledby="dropdownUser" class="dropdown-menu dropdown-menu-end">
            <p class="dropdown-item welcome-message">Welkom,</p>
            <div class="dropdown-item">
              <i class="far fa-user"></i> <b>{{userData.Person.firstName}} {{userData.Person.lastName}}</b>
            </div>
            <div class="dropdown-divider"></div>
            <a (click)="changeCompany()" class="dropdown-item">
              <i class="far fa-building"></i> {{userData.Company.name}}
              <p class="changeCompanyText">(klik om te wijzigen)</p>
            </a>
            <div class="dropdown-divider"></div>
            <a *ngFor="let index of headermenu" class="dropdown-item" [routerLink]="index.href">
              <i class="{{index.icon}}"></i> {{index.title}}
            </a>
            <a href="#" class="dropdown-item" (click)="logout()">
              <i class="fas fa-sign-out-alt"></i> Uitloggen
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Navbar -->
<nav class="navbar navbar-expand-lg navbar-light" style="background-color: #63b5a2;">
  <div class="container">
    <button class="navbar-toggler" type="button" (click)="isCollapsed = !isCollapsed" aria-controls="navbarText"
      aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div [ngbCollapse]="isCollapsed" class="collapse navbar-collapse" id="navbarText">
      <ul class="navbar-nav mr-auto">
        <li *ngFor="let index of mainmenu" class="nav-item">
          <a class="nav-link text-light" routerLinkActive="active" [routerLink]="index.href">
            <i [class]="index.icon"></i> {{index.title}}
          </a>
        </li>
      </ul>
    </div>
  </div>
</nav>